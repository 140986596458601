import React from "react";  

function Footer() {
  return (
    <div className="">
      <h1>Footer</h1>
      <p>Footer links</p>
    </div>
  );
}

export default Footer;