import React from 'react';

function Home(){
  return(
    <div>
      <h1>Home</h1>
      <p>Home page body content</p>
    </div>
  )
}

export default Home;